import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import HeroScalingTag from "@/game-deadlock/components/HeroStatsScalingTag.jsx";
import { SCALING_TAGS } from "@/game-deadlock/constants/hero-stats-scaling.mjs";
import { TableContainer } from "@/game-deadlock/Heroes.style.jsx";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import { heroWinrateColor } from "@/game-deadlock/utils/hero-winrate-color.mjs";
import { localeString } from "@/game-deadlock/utils/localestring.mjs";
import { useHeroesStats } from "@/game-deadlock/utils/use-heroes-stats.mjs";
import DataTable, { DataTableLoading } from "@/shared/DataTable.jsx";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

export default function Heroes() {
  const { t } = useTranslation();
  const { loading: heroesStatsLoading, stats: heroesStats } = useHeroesStats();

  const latestPatch = heroesStats[0]?.patch;

  const diffInTime: number =
    latestPatch && new Date().getTime() - new Date(latestPatch).getTime();
  const diffInDays: number = Math.floor(diffInTime / (1000 * 60 * 60 * 24));

  return (
    <PageContainer>
      <PageHeader
        imageRound
        image={Assets.getDeadlockLogo()}
        title={t(
          "deadlock:herosStats.title",
          "Deadlock Heroes Stats & Tier List",
        )}
      />
      <div
        className="type-caption--bold shade1"
        style={{
          display: "flex",
          gap: "var(--sp-2)",
          opacity: heroesStats[0]?.total_num_matches ? 1 : 0.38,
        }}
      >
        {heroesStatsLoading ? (
          <span>{t("", "Loading...")}</span>
        ) : latestPatch ? (
          <>
            <span>
              <Trans i18nKey="common:stats.patchTimeAgo">
                Patch:{" "}
                <strong style={{ color: "var(--shade0)" }}>
                  {diffInDays.toLocaleString()} Days Ago
                </strong>
              </Trans>
            </span>
            {" • "}
            <span>
              {t(
                "common:stats.matchesCountPlayed",
                "{{matchesPlayed}} Matches Played",
                {
                  matchesPlayed: localeString(heroesStats[0].total_num_matches),
                },
              )}
            </span>
            {" • "}
            <span>
              {t(
                "deadlock:stats.plaeyrsCountAggregated",
                "{{playersCount}} Players Aggregated",
                {
                  playersCount: localeString(
                    heroesStats[0].total_num_match_players,
                  ),
                },
              )}
            </span>
          </>
        ) : null}
      </div>
      {heroesStatsLoading ? (
        <DataTableLoading tallRows loadingRows={20} />
      ) : (
        <DataTable
          tallRows
          cols={[
            {
              align: "left",
              display: t("deadlock:heroesStats.hero", "Hero"),
              className: "hero-meta",
            },
            {
              display: t("common:stats.tier", "Tier"),
              className: "tier",
              primary: true,
            },
            {
              display: t("common:stats.winRate", "Win Rate"),
              className: "winrate",
              isStat: true,
            },
            {
              display: t("common:stats.scaling", "Scaling"),
              className: "scaling",
              isStat: true,
            },
            {
              display: t("common:stats.appearance", "Appearance"),
              className: "appearance",
              isStat: true,
            },
            {
              display: t("common:stats.kda", "KDA"),
              className: "kda",
              isStat: true,
            },
            {
              display: t("deadlock:stats.souls", "Souls"),
              className: "souls",
              isStat: true,
            },
            {
              display: t("common:stats.matches", "Matches"),
              className: "matches",
              isStat: true,
            },
          ]}
          rows={heroesStats.map((hero, i) => {
            const TierIcon = getTierIcon(hero.tier);
            return [
              {
                display: (
                  <div className="hero-meta">
                    <div className="hero-img">
                      <img
                        src={Assets.getHeroPortrait(hero.heroId)}
                        width="36"
                        height="48"
                        loading={i > 15 ? "lazy" : "eager"}
                        alt={
                          hero.heroName
                            ? t(
                                "deadlock:heroImageAltWithName",
                                "{{heroName}} Deadlock Hero",
                                {
                                  heroName: hero.heroName,
                                },
                              )
                            : t("deadlock:heroImageAltNoName", "Deadlock Hero")
                        }
                      />
                    </div>

                    <span>{hero.heroName}</span>
                  </div>
                ),
                link: `/deadlock/heroes/${hero.heroName}`,
                value: hero.heroName || hero.heroId,
              },
              {
                display: TierIcon ? (
                  <img src={TierIcon} width="28" height="28" />
                ) : (
                  hero.tier
                ),
                value: hero.tier * -1,
              },
              {
                display: localeString(hero.win_rate, true, 1),
                value: hero.win_rate,
                style: { color: heroWinrateColor(hero.win_rate) },
              },
              {
                display: (
                  <HeroScalingTag
                    heroStats={hero}
                    className="type-caption--bold"
                  />
                ),
                value:
                  SCALING_TAGS[
                    hero?.hero_match_duration_type?.hero_duration_type
                  ]?.value || 0,
              },
              {
                display: localeString(hero.appearance_rate, true, 0),
                value: hero.appearance_rate,
              },
              {
                display: localeString(hero.avg_kda, false, 1),
                value: hero.avg_kda,
              },
              {
                display: localeString(hero.avg_net_worth),
                value: hero.avg_net_worth,
              },
              {
                display: localeString(hero.num_matches),
                value: hero.num_matches,
              },
            ];
          })}
          indexCol
          className={TableContainer()}
          sortCol={1} // Tier
          sortColTiebreak={2} // Win Rate
          sortDir="DESC"
          searchText={""}
          searchCol={1}
        />
      )}
    </PageContainer>
  );
}

export function meta([heroId]) {
  const hero = readState.deadlock.heroes[heroId];
  const name = hero?.heroName;
  return {
    title: name
      ? [
          "deadlock:meta.heroPage.title",
          "{{name}}'s Win Rate, Pick Rate, and tier list – Blitz Deadlock",
          { name },
        ]
      : [
          "deadlock:meta.tierlist.title",
          "Deadlock Tier list, Win Rates, Pick Rates, and more – Blitz Deadlock",
        ],
    description: [],
  };
}
